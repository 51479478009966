import _ from "lodash";

export const roles = {
    counsellor: 1,
    healthcoach: 2,
    caremgr: 3,
    nutritionist: 4,
    pharma: 5,
    doctor: 6,
    admin: 7,
    custmgr: 8,
    member: 9,
    hr: 15,
    fitnesscoach: 16,
    healthfacilitator: 17,
    medicalofficer: 12,
    hrAdmin: 18,
};

export const roleslist = [
    {
        id: "1",
        name: "Counsellor",
    },
    {
        id: "2",
        name: "Health Coach",
    },
    {
        id: "3",
        name: "Care Manager",
    },
    {
        id: "4",
        name: "Nutritionist",
    },
    {
        id: "5",
        name: "Pharmacologist",
    },
    {
        id: "6",
        name: "Doctor",
    },
    {
        id: "7",
        name: "Admin",
    },
    {
        id: "8",
        name: "Customer Manager",
    },
    {
        id: "15",
        name: "HR",
    },
    {
        id: "18",
        name: "HRAdmin",
    },
];

export const entitlementList = {
    apiAuthorization: "api_authorization",
    memberListing: "memberlisting",
    onboarding: "onboarding",
    notification: "notification",
    createTodo: "create_todo",
    viewMedicationTemplate: "view_medication_template",
    viewMealPlan: "view_meal_plan",
    assignCareTeam: "assign_care_team",
    assignDevices: "assign_devices",
    viewTodoTemplate: "view_todo_template",
    createMedicationTemplate: "create_medication_template",
    createMealPlan: "create_meal_plan",
    nutriOMeter: "nutri-o-meter",
    dialyTodoLog: "dialy-todo_log",
    manageData: "manage_data",
    manageUsers: "manage_users",
    memberFeedback: "member-feedback",
    devices: "devices",
    memberEngagement: "member-engagement",
    // appointments: 'appointments',
    labRequisition: "lab_requisition",
    memberReferrals: "member-referrals",
    memberEnquiries: "member-enquiries",
    memberBilling: "member-billing",
    others: "others",
    assessment: "assessment",
    alertsList: "alerts-list",
    existingMembers: "existing-members",
    newMembers: "new-members",
    chats: "chats",
    trends: "trends",
    createPcp: "create_pcp",
    viewApprovePcp: "view_approve_pcp",
    riskScoring: "risk-scoring",
    setAlertsList: "set-alerts-list",
    adherenceMeter: "adherence-meter",
    memberProgressionScore: "member-progression-score",
    assignTags: "assign-tags",
    careManagerList: "care-manager-list",
    goals: "goals",
    setThresholds: "set-thresholds",
    foodAlerts: "food-alerts",
    medicationAlerts: "medication-alerts",
    trendsPriority: "trends-priority",
    medicationDatabase: "medication-database",
    medicalTestSummary: "medical-test-summary",
    nutritionSummary: "nutrition_summary",
    nutrioMeter: "nutri-o-meter",
    treatingDoctorSummary: "treating-doctor-summary",
    approvals: "approvals",
    memberStatisticsReport: "member_statistics_report",
    memberMedicineReport: "member_medicine_report",
    addVitals: "add_vitals",
    deleteVitals: "vital_delete",
    leadListingCounselor: "counselor_lead_listing",
    labRequest: "lab_requisition",
    leadListingCustExp: "customer_exp_lead_listing",
    createMealLog: "add_manual_meal_log",
    deleteManualMealLog: "delete_manual_meal_log",
    addNotes: "add_notes",
    addHealthProfile: "add_health_record",
    editHealthProfile: "add_health_record",
    appointments: "appointment",
    createAppointment: "create_appointment",
    myAppointment: "my_ppointment",
    prescription: "prescription",
    labs: "labs",
    scores: "adherence_score",
    labapproval: "lab_approval",
    uploadedPrescription: "uploaded_prescription",
    executiveSummary: "executive_summary",
    prescriptionApproval: "prescription_approval",
    memberRegistration: "member_registration",
    addPatient: "add_my_patient",
    profile_setting: "profile_setting",
    bulkUpload: "bulk_upload",
    viewUploadedReports: "view_uploaded_reports",
    mealPlanDownload: "download_meal_plan",
    hrDashBoard: "hr_dashboard",
    dashboard: "treating_doctor_dashboard",
    chat: "chat",
    addMember: "add_member",
    statisticsList: "member_statistics",
};

export const getUserDetails = (field: string | null = null) => {
    const userDetails = JSON.parse(
        localStorage.getItem("userDetails") || "null"
    );
    if (userDetails) {
        if (field) {
            return userDetails[field] || null;
        } else {
            return userDetails;
        }
    }
    return null;
};

export const setUserDetails = (field: string | object, value: any = "") => {
    if (typeof field === "string") {
        field = {
            [field]: value,
        };
    }

    const userDetails = JSON.parse(
        localStorage.getItem("userDetails") || "null"
    );
    if (userDetails && typeof field === "object") {
        const newuserDetails = {
            ...userDetails,
            ...field,
        };
        localStorage.setItem("userDetails", JSON.stringify(newuserDetails));
    }
    return null;
};

export const getCurrentRoleKey = (currentRole = null) => {
    const role_name: string = getUserDetails("role_name") || "";
    const role_path: string = role_name.replaceAll(" ", "").toLowerCase();

    currentRole = currentRole || getUserDetails("role_id");
    let currentRoleKey = _.findKey(roles, (role) => role === currentRole);
    return currentRoleKey || role_path;
};

export const convertRoles = (
    roles: Array<number> | string | number = []
): Array<number> => {
    if (typeof roles === "string") {
        roles = roles.split(",").map((i) => parseInt(i));
    }
    if (typeof roles === "number") {
        roles = [roles];
    }
    return roles;
};

export function isRole(roles: Array<number> | string | number = []): boolean {
    roles = convertRoles(roles);

    const currentRole = getUserDetails("role_id");
    return !!currentRole && roles.includes(currentRole);
}

export function isNotRole(
    roles: Array<number> | string | number = []
): boolean {
    roles = convertRoles(roles);

    const currentRole = getUserDetails("role_id");
    return !!currentRole && !roles.includes(currentRole);
}

export function getCurrentEntitlementList() {
    let currentEntitlementList = getUserDetails("entitlementlist");
    if (currentEntitlementList && currentEntitlementList.length) {
        currentEntitlementList = currentEntitlementList.map((e) => e.name);
    } else {
        currentEntitlementList = [];
    }

    return currentEntitlementList;
}

export function can(entitlement) {
    const currentEntitlementList = getCurrentEntitlementList();
    return currentEntitlementList.includes(entitlement);
}

export function EqAccessGate({
    isRole: role,
    children = null,
    entitlement = null,
    ifDenied = null,
}: {
    isRole?: Array<number> | number | string;
    entitlement?: string | null;
    children?: any;
    ifDenied?: any;
}) {
    let canDo = true;

    if (role) {
        canDo = canDo && isRole(role);
    }
    if (entitlement) {
        canDo = canDo && can(entitlement);
    }

    if (canDo) {
        return children;
    } else {
        return ifDenied;
    }
}

export function EqAccessDenyGate({ isNotRole: role, children }) {
    const can = isNotRole(role);
    if (can) {
        return children;
    } else {
        return null;
    }
}
